@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400:600:700&display=swap');


.nav {
  list-style-type:none;
}

.nav li {
  display: inline;
  margin-left: 10px;
}

img {
  max-width:100%;
  max-height:100%;
}

.home-section{
  background-color:lightgrey;
  padding-bottom: 20px;
  margin-bottom:3px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
